import React, { useRef, useEffect, useState, useLayoutEffect } from "react";
import {
  useSpring,
  animated,
  SpringValue,
  useChain,
  useSpringRef,
  useTransition,
  useSprings
} from "react-spring";
import style from "./style.module.less";
import useDimensions from "react-use-dimensions";

export default (_props: { progress: number }) => {
  let [{ c }] = useState({ c: new SpringValue() });
  const [ref, { x, y, height }] = useDimensions();
  c.set(_props.progress);
  return (
    
      <animated.div
      ref={ref}
      style={{
        scale: c.to([-1,0,1,2],[1,1,0,0]),
        y: c.to([-1,0,1],[(height/window.innerHeight),0,-(height/window.innerHeight)]).to(c => c * 100 + "%")
      }}
      >
       <div className="w-100">
        <animated.div className={"row"} style={{
          //x:c.to([-1,0,1],[-1,0,0]).to(c=>c*100+"%")
          }}>
        
          <div className="col-12 col-md-7">
              <p className={style.text}>
                基于动态特征识别能力，通过肢体关键点识别、高精度边缘识别，实现针对真人动作（视频文件或RGB摄像头实时检测）的动作捕捉，驱动指定形象
                “动”起来，以及短视频、AR混合现实、直播等风格化内容创作生成。
              </p>
            </div>
            <div className="col-12 col-md-5">
              <img
                src={require("@/images/图片 1.7.png")}
                style={{ maxWidth: "100%" }}
                alt=""
              />
            </div>
          
        </animated.div>
        <animated.div  style={{
          //x:c.to([-1,0,1],[-1,0,0]).to(c=>c*-100+"%")
          }} className={"d-flex justify-content-between mt-4 " + style.text1}>
          
        <div className="row">
            <div className="col-auto flex-wrap">
              <img
                src={require("@/images/图片01.png-1.png")}
                style={{ height: 160 }}
                alt=""
              />
              <p className="mt-1">驱动虚拟形象同步联动，与人共舞</p>
            </div>
            <div className="col-auto">
              <img
                src={require("@/images/图片01.png-2.png")}
                style={{ height: 160 }}
                alt=""
              />
              <p className="mt-1">按需切换视频背景/场景</p>
            </div>
            <div className="col-auto">
              <img
                src={require("@/images/图片01.png-3.png")}
                style={{ height: 160 }}
                alt=""
              />
              <p className="mt-1">人体关节点动捕生成</p>
            </div>
          </div>
        </animated.div>
       </div>
    </animated.div>
  );
};
