import React, { useRef, useEffect, useCallback, useState } from "react";
import Window, {
  WindowRef,
  useWindow,
  RouterConfig
} from "@components/window/index";

import "@/App.less";
import style from "./style.module.less";

import { createBrowserHistory, History } from "history";
import Page1 from "./page1";
import Page2 from "./page2";
import Page3 from "./page3";

let routers: RouterConfig[] = [
  {
    path: "/",
    component: Page1
  }
];
function App() {
  return (
    <div className={`${style.container} container-fluid  d-flex flex-column h-100`}>
     
      <Page1>

      </Page1>
      
    </div>
  );
}

export default App;
