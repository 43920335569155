import React, { useRef, useEffect, useState, useLayoutEffect } from "react";
import {
  useSpring,
  animated,
  SpringValue,
  useChain,
  useSpringRef,
  useTransition,
  useSprings
} from "react-spring";
import style from "./style.module.less";
import useDimensions from "react-use-dimensions";
export default (_props: { progress: number }) => {
  let [{ c }] = useState({ c: new SpringValue(-1) });
  const [ref, { x, y, height }] = useDimensions();

  c.set(_props.progress);
 
  return (
    <animated.div
      ref={ref}
      style={{
        scale: c.to([-1,0,1,2],[1,1,0,0]),
        y: c
          .to(
            [-1, 0, 1],
            [height / window.innerHeight, 0, -(height / window.innerHeight) ]
          )
          .to(c => c * 100 + "%")
      }}
      className={[_props.progress>=0 && _props.progress<=1 && "scroll-active"].filter(Boolean).join(" ")+" row   justify-content-center align-items-center"}
    >
      <animated.div className="col-12 col-md-3 text-center " style={{
       
      }}>
        <div className="fadeInUp">
          <p>
            <img
              src={require("@/images/图片 1.png")}
              style={{ width: 150, maxWidth: "100%" }}
              alt=""
            />
          </p>
          <p>致力于艺术普惠大众</p>
        </div>
      </animated.div>
      <animated.div className="col-12 col-md-9 overflow-hidden " style={{ 
        
        }}>
        <div className="fadeInUp">
          <div className="pl-md-5">
            <h5> ·核心领域：机器生成内容</h5>
            <p>
              覆盖图像生成、音乐生成的视听领域及虚拟形象人机交互等复合应用；
            </p>
            <h5> ·核心能力：少样本学习生成</h5>
            <p>
              根据场景及特定样本的互动创作、演绎体验、合成生成、影音素材库、可编程艺术、生成艺术等；
            </p>
            <h5> ·服务模式：</h5>
            <p>展览展示镇馆体验，创作工具，版权及加密艺术，美育平台。</p>
          </div>
        </div>
      </animated.div>
    </animated.div>
  );
};
