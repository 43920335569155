import React, { useRef, useEffect, useState, useLayoutEffect } from "react";
import {
  useSpring,
  animated,
  SpringValue,
  useChain,
  useSpringRef,
  useTransition,
  useSprings
} from "react-spring";
import style from "./style.module.less";
import useDimensions from "react-use-dimensions";
export default (_props: { progress: number }) => {
  let [{ c }] = useState({ c: new SpringValue() });
  const [ref, { x, y, height }] = useDimensions();
  c.set(_props.progress);
  
  return (
    <animated.div
      ref={ref}
      style={{
        scale: c.to([-1,0,1,2],[1,1,0,0]),
        y: c.to([-1,0,1,2],[1,0,-1,-1]).to(c => c * 100 + "%"),
        
      }}
      className="d-flex flex-column    justify-content-center align-items-center"
    >
      <div className="row">
        <animated.div
          className="col-12 col-lg-8 col-md-8 "
          style={{
            
          }}
        >
          <h3>一、图像生成（image generation）</h3>
          <div className="d-flex mt-4">
            <div className="">
              从肖像速写起步，Wonderina（云图里）通过机器学习培育了首位机器人画家琥珀（Wonder
              Hopper），通过大规模图片数据库学习，提取物种/物件/人物的特征（实现特征提取），再用线条绘制出对象最特别的地方（实现特征重现），从而完成线稿创作。
            </div>
          </div>
        </animated.div>
        <animated.div
          style={{
            
          }}
          className="col text-center text-md-right mt-2 mt-md-0"
        >
          <img
            src={require("@/images/图片 12.png")}
            style={{ width: 200, maxWidth: "100%" }}
            alt=""
          />
        </animated.div>
        <animated.div
          style={{
           
          }}
          className="col text-center text-md-right mt-2 mt-md-0"
        >
          <img
            src={require("@/images/图片 11.png")}
            style={{ width: 200, maxWidth: "100%" }}
            alt=""
          />
        </animated.div>
      </div>

      <div className="row">
        {" "}
        <animated.div
          style={{
            
          }}
          className="col-12 mt-4"
        >
          <img
            src={require("@/images/图片 14.png")}
            style={{ maxWidth: "100%" }}
            alt=""
          />
        </animated.div>
      </div>
    </animated.div>
  );
};
