import React, { useRef, useEffect, useState, useLayoutEffect } from "react";
import { CSSTransition } from "react-transition-group";
import style from "./style.module.less";
import * as $ from "jquery";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Mousewheel, Scrollbar } from "swiper";
import "swiper/swiper.less";
import "swiper/components/scrollbar/scrollbar.less";
import Slide1 from "./Slide1";
import Slide2 from "./Slide2";
import Slide3 from "./Slide3";
import Slide4 from "./Slide4";
import Slide5 from "./Slide5";
import Slide6 from "./Slide6";
import Slide7 from "./Slide7";
import Slide8 from "./Slide8";
import SlideWrap from "./SlideWrap";
import { SpringValue } from "react-spring";
SwiperCore.use([Mousewheel, Scrollbar]);
// Import Swiper styles

export default _props => {
  let timer = useRef<any>();
  let [offsetY, setOffsetY] = useState(-100);
  function slideToClosest(a, b) {
    clearTimeout(timer.current);
    timer.current = setTimeout(function () {
      let i = Math.round(b / window.innerHeight);
      let y = i * window.innerHeight;
      let max = -a.snapGrid[a.snapGrid.length - 1];
      if (y != b && b != max) {
        offsetY = b;
        setOffsetY(b);
        a.translateTo(y, 300, false, false);
      }
    }, 200);
  }

  let Swipers = [
    { name: Slide1, height: 1 },
    { name: Slide2, height: 1 },
    { name: Slide3, height: 1 },
    { name: Slide4, height: 1 },

    { name: Slide5, height: 1 },
    { name: Slide6, height: 1 },
    { name: Slide7, height: 1 },
    { name: Slide8, height: 1 }
  ];

  let [spring] = useState(
    new SpringValue({
      to: -1,
      onChange: function (a) {
        onUpdate({ value: a });
      }
    })
  );

  let [totalPie] = useState(function () {
    let h = 0;
    Swipers.forEach(item => {
      h += item.height;
    });
    return h;
  });

  let [totalTime] = useState(function () {
    let h = 0;
    Swipers.forEach(item => {
      h += item.height;
    });
    return (h / 4) * 20;
  });

  let [grids] = useState(function () {
    let h = 0;
    let arr = [];
    Swipers.forEach(item => {
      arr.push(h);
      h += item.height;
    });
    return arr;
  });
  let [, requestUpdate] = useState(0)
  let [data, setData] = useState({
    delayTime: 0,
    swiper: (null as unknown) as SwiperCore,

    targetProgress: 1
  });
  let { targetProgress, swiper, delayTime } = data;
  let progress = useRef(0);
  let activeIndex = useRef(0);

  let onUpdate = function (targets: any) {
    let _progress = targets.value;
    progress.current = _progress;

    activeIndex.current = Math.floor(_progress * totalPie);
    requestUpdate(c => c + 1);
  };

    
    let tweenChange = function (val, time) {

    };

  let handleTargetProgress = function (val, time, time1) {
    spring.start({ to: val });
  };
  return (
    <>

      <div className="row  h-100 align-items-center position-relative">
        <div className="w-100 h-100 overflow-auto " onScroll={(e) => {
          let c = (e.target.scrollTop / (e.target.scrollHeight - e.target.clientHeight));
          swiper.setProgress(c)

        }}> <div style={{ height: totalPie * 200 + "vh" }}>
            <Swiper
              direction="vertical"
              freeMode={true}
              virtualTranslate={true}
              slidesPerView={"auto"}
              simulateTouch={false}
              freeModeMomentumVelocityRatio={0.2}
              style={{ height: "100vh", position: "sticky", top: 0, width: "100%" }}
              touchEventsTarget={"wrapper"}

              onSwiper={e => {
                setData(c => ({ ...c, swiper: e }));
              }}
              onProgress={(a, b) => {

                let enabled = !a.touchEventsData.isTouchEvent;

                handleTargetProgress(a.progress, 0, !enabled ? 1 : 0.2);
              }}
            >
              <div slot="container-start" className={"row   " + style.navbar_wrap}>
                <div className={" container  "}>
                  <nav
                    className={`${style.navbar} navbar  navbar-default`}

                  >
                    <div className="navbar-header">
                      <a className="navbar-brand" href="#">
                      <img alt="Brand"
    className={`${style.logo}`}
    style={{opacity:progress.current <0.075  || progress.current > 0.55?1:0}}
                          src={require("./images/Group.png")}
                        />
 
 <img
                          alt="Brand"
      className={`${style.logo}`}
      style={{maxHeight:80,opacity:progress.current >= 0.075 && progress.current < 0.4?1:0}}
                          src={require("../../images/机器人琥珀.png")}
                        />
 
<img
                          alt="Brand"
      className={`${style.logo}`}
      style={{opacity:progress.current >= 0.4 && progress.current <= 0.55?1:0}}
                          src={require("../../images/wondermusician.png")}
                        />
  
                      </a>
                    </div>
                  </nav>
                </div>
              </div>
              {Swipers.map((Comp, index) => (
                <SwiperSlide
                  virtualIndex={index}
                  key={index}
                  style={{ height: Comp.height * 100 + "vh" }}
                >
                  {({ isActive, isPrev, isNext }) => {
                    return (
                      <SlideWrap
                        totalPie={totalPie}
                        isActive={isActive}
                        isNext={isNext}
                        isPrev={isPrev}
                        index={index}
                        grids={grids}
                        Comp={Comp}
                        progress={progress.current}
                      ></SlideWrap>
                    );
                  }}
                </SwiperSlide>
              ))}


            </Swiper>
          </div>
        </div>
        {swiper && !swiper.isEnd && (
          <div className={style.arrow + " d-sm-none"}>
            <img src={require("./images/箭头.png")} alt="" />
          </div>
        )}
      </div>
    </>
  );
};
