// These must be the first lines in src/index.js
//支持ie9及以上浏览器
import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';

import React from "react";
import ReactDOM from "react-dom";
import "@/../node_modules/bootstrap/dist/css/bootstrap.min.css";
import App from "./App";
import {AppContainer} from 'react-hot-loader'

import * as serviceWorker from '@/serviceWorker'

ReactDOM.render(
  <React.StrictMode>
    <AppContainer>
      <App></App>
    </AppContainer>
  </React.StrictMode>,
  document.getElementById("root")
);
if ((module as any).hot) {
  (module as any).hot.accept('./App', () => {
    const App = require('./App').default;
    ReactDOM.render(
      <App />,
      document.getElementById("root")
    );
  });
}


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();