import React, { useRef, useEffect, useState, useLayoutEffect } from "react";
import {
  useSpring,
  animated,
  SpringValue,
  useChain,
  useSpringRef,
  useTransition,
  useSprings
} from "react-spring";
import style from "./style.module.less";

export default (_props: { progress: number }) => {
  let [{ c }] = useState({ c: new SpringValue() });

  c.set(_props.progress);
  return (
    <animated.div
      style={{
        scale: c.to([-1,0,1,2],[1,1,0,0]),
        y: c.to([-1,0,1],[1,0,-1]).to(c => c * 100 + "%")
      }}
    >
     
        <div className="row    align-items-center  position-relative">
          <animated.div style={{
            
            x:c.to([-1,0,0.1,1],[-1,0,0,0]).to(c=>c*100+"%")}} className="col-12 d-flex justify-content-center col-lg-auto">
            <div className={"" + style["img-wrap"]}>
              <img
                src={require("@/images/图片 132.png")}
                style={{ maxWidth: "100%", width: 300 }}
                alt=""
              />
            </div>
          </animated.div>
          <animated.div style={{
           
            x:c.to([-1,0,0.1,1],[1,0,0,0]).to(c=>c*100+"%")}} className="col-12 col-lg pl-lg-5">
            <h3 className={"mb-2 mb-md-4 mt-4 mt-md-0"}>三、应用示例</h3>
            <p className={style.text}>
              ►智能画架——微处理器集成传统画架，现场用户扫码上传头像，机械臂5分钟内完成一笔螺旋画肖像速写
            </p>
            <p className={style.text}>
              ►一起画大屏——用户在触屏上画一笔，后续由机器补充画完，完成合作创作（简笔画手绘风格），素材主题可定义
            </p>
            <p className={style.text}>
              ►舞台剧——《机器人奇遇记之绘梦机器人》以画画机器人琥珀为原型并主演，2018年6月在广州大剧院首演
            </p>
            <p className={style.text}>
              ►人与AI合成艺术大展——“圆·梦”，与青年艺术家张巍联合策展，以圆形重构张巍的“人工剧团”系列作品
            </p>
            
            <p className={style.text}>
              ►体感艺术装置——奇妙指挥家，在广州CBD天德广场“奏响2021·奇妙音乐会”，以手势感应控制乐曲节奏、控制灯光，一个人奏响一台音乐会
            </p>
          </animated.div>
        </div>
     
    </animated.div>
  );
};
