import React, { useRef, useEffect, useState, useLayoutEffect } from "react";
import {
  useSpring,
  animated,
  SpringValue,
  useChain,
  useSpringRef,
  useTransition,
  useSprings
} from "react-spring";
import style from "./style.module.less";
import useDimensions from "react-use-dimensions";
export default (_props: { progress: number }) => {
  let [{ c }] = useState({ c: new SpringValue() });
  const [ref, { x, y, height }] = useDimensions();
  c.set(_props.progress);
  return (
    <animated.div
      ref={ref}
      style={{
        scale: c.to([-1, 0, 1, 2], [1, 1, 0, 0]),
        y: c
          .to(
            [-1, 0, 1],
            [height / window.innerHeight, 0, -(height / window.innerHeight)]
          )
          .to((c) => c * 100 + '%'),
      }}
    >
      <div className='row  justify-content-center align-items-center'>
        <animated.div
          style={{
            x: c.to([-1, 0, 1], [-1, 0, 0]).to((c) => c * 100 + '%'),
          }}
          className='col-12 col-lg-9 col-md-9 d-flex flex-column justify-content-between'
        >
          <div>
            <h3>二、音乐生成（music generation）</h3>
            <div className='mt-4'>
              <div className=''>
                基于图像生成，通过基于图像纹理追踪的CNNs模型识别图像主体、主体结构与线条变化，再利用模型生成相应的MIDI文件，经过特定乐器混音，生成纯音乐作品，风格涵盖古典、轻快、节奏、NewAge冥想等。
              </div>
              <br></br>
              <div>
                公司现已成立机器自主音乐生成、AI音乐娱乐厂牌WonderMusician，可支持：
                <br></br>
                ①人机互动音乐生成服务；<br></br>
                ②AI交互音乐轻娱乐教育服务；<br></br>
                ③AI元素互动音乐会服务；<br></br>
                ④人机互动的跨模态音乐体感游戏搭建及设施供应。
              </div>
            </div>
          </div>
          <div className='row mt-4'>
            <img src={require('@/images/图片001.png')} className='col-12' />
          </div>
        </animated.div>
        <animated.div
          style={{
            x: c.to([-1, 0, 1], [1, 0, 0]).to((c) => c * 100 + '%'),
          }}
          className='col'
        >
          <div className='row mt-4 mt-md-0  text-center'>
            <div className='col-6 col-md-12 '>
              <img
                src={require('@/images/图片1 1.png')}
                style={{ maxWidth: '100%' }}
                alt=''
              />
              <p>《Yosemite 》</p>
            </div>
            <div className='col-6 col-md-12 '>
              <img
                src={require('@/images/图片 221.png')}
                style={{ maxWidth: '100%' }}
                alt=''
              />
              <p className='mb-0'>《M51 》</p>
            </div>
          </div>
        </animated.div>
      </div>
    </animated.div>
  );
};
