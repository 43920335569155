import React, { useRef, useEffect, useCallback, useState } from "react";
import { useSpring, animated, SpringValue, useChain, useSpringRef, useTransition, useSprings } from 'react-spring'



export default function Component(_: {grids:any;index:number,isPrev?:boolean,isNext?:boolean, totalPie:number;Comp:any, isActive?: boolean,  progress: number }) {

  let Comp=_.Comp;
  let grids=_.grids;
  let index=_.index;
  let totalPie=_.totalPie;
  let progress=_.progress;
  let endHeight=grids[index]+Comp.height;
  let start=grids[index]/totalPie;
  let end=endHeight/totalPie;
 
  let current=(progress-start)/(1/totalPie);
  current=Math.min(Comp.height+1,current);
  current=Math.max(-1,current);

 
  return <div className={"container"}>
   {
    (_.isActive || _.isPrev || _.isNext) && <Comp.name 
    max={Comp.height+1} 
    isActive={_.isActive} 
    start={start} 
    end={end} 
    progress={current}/>}
  </div>
}

