import React, { useRef, useEffect, useState, useLayoutEffect } from "react";
import {
  useSpring,
  animated,
  SpringValue,
  useChain,
  useSpringRef,
  useTransition,
  useSprings
} from "react-spring";
import style from "./style.module.less";
import useDimensions from "react-use-dimensions";
export default (_props: { progress: number }) => {
  let [{ c }] = useState({ c: new SpringValue() });
  const [ref, { x, y, height }] = useDimensions();
  c.set(_props.progress);
  return (
    <animated.div
    ref={ref}
    style={{
      scale: c.to([-1,0,1,2],[1,1,0,0]),
      y: c.to([-1,0,1,2],[1,0,-1,-1]).to(c => c * 100 + "%"),
      
      }}
      className="row    justify-content-center align-items-center"
    >
       <animated.div style={{
         
          }} className="col-12 ">
      <img src={require("@/images/图片 16.png")} style={{maxWidth:"100%"}}  alt=""/>
      </animated.div>
      <animated.div style={{
           
          }} className={"col-12 mt-4 mt-md-0 col-md-6 "+style['slide3_1']}  >
      围绕“特征重现”，进一步应用圆形、三角形、螺旋线等基础几何元素进行符号解构和生成表达，创造了多样而又富创意的“生成艺术” （generative art），包括用机械臂缠绕实体丝线形成的线绳工艺画（String Art）。

      </animated.div>
     
    </animated.div>
  );
};
