import React, { useRef, useEffect, useState, useLayoutEffect } from "react";
import {
  useSpring,
  animated,
  SpringValue,
  useChain,
  useSpringRef,
  useTransition,
  useSprings
} from "react-spring";
import style from "./style.module.less";

export default (_props: { progress: number }) => {
  let [{ c }] = useState({ c: new SpringValue() });

  c.set(_props.progress);
  return (
    <animated.div
      style={{
        scale: c.to([-1,0,1,2],[1,1,0,0]),
        y: c.to([-1,0,1],[1,0,-1]).to(c => c * 100 + "%")
      }}
    >
      <div className="row    justify-content-center align-items-center">
        <animated.div className="col" style={{x:c.to([-1,0,0.1,1],[-1,0,0,0]).to(c=>c*100+"%")}} className="col-12  col-md-7 d-flex flex-column justify-content-between">
          <div>
            <h3>►基于少样本学习的生成技术——艺术普惠大众</h3>
            <div className="d-flex mt-4">
              <div className={style.text}>
                基于语义切割的少样本学习模型FLSNets（Few-Shot Learning With
                Semantic-Segmentation
                Networks），核心基于梯度长记忆模型的元学习(Meta-Learning with
                Long-term Memory），实现少样本下具有稀缺标记的数据训练并生成。
                在艺术领域，可基于单幅或多幅同一艺术家作品完成训练过程，并实现毫秒级的语义重构后的图像再生成。这为大众走进艺术提供了第一视角——用创作者的视角，用全局视野去从0到1动手布局，从而理解其创作心境和风格，乃至为我所用，激发自身了解并学习表达方法，提升审美能力和艺术素养。
              </div>
            </div>
          </div>
        </animated.div>
        <animated.div className="col" style={{x:c.to([-1,0,0.1,1],[1,0,0,0]).to(c=>c*100+"%")}} className="col">
          <img
            src={require("@/images/图片 112312.png")}
            style={{ maxWidth: "100%" }}
            alt=""
          />
          <p className={style.text1}>
            用户只需完成涂鸦式地布局（涂抹出花瓶及花），它就能生成你喜欢或想要的大师样子
          </p>
        </animated.div>
      </div>
    </animated.div>
  );
};
