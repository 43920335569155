import React, { useRef, useEffect, useState, useLayoutEffect } from "react";
import {
  useSpring,
  animated,
  SpringValue,
  useChain,
  useSpringRef,
  useTransition,
  useSprings
} from "react-spring";
import style from "./style.module.less";

export default (_props: { progress: number }) => {
  let [{ c }] = useState({ c: new SpringValue() });

  c.set(_props.progress);

  let [ref]=useState(React.createRef<HTMLDivElement>());
  let [height,setHeight]=useState(0);
  useEffect(function(){
    if(ref && ref.current){
      let rect=ref.current.getBoundingClientRect();
      setHeight(rect.height-window.innerHeight);
    }
  },[ref])
  return (
    <animated.div
    ref={ref}
      style={{
        y: c.to([-1,0,1],[window.innerHeight,0,-height]).to(c => c+"px")
      }}
    >
      <div className="row    justify-content-center align-items-center">
        <animated.div className="col-12  col-md-7 d-flex flex-column justify-content-between"
         style={{x:c.to([-1,0.5,1],[-1,0,0]).to(c=>c*100+"%")}}>
          <div>
            <h3>美育，从中国的传世名画开始——传世名画该如何传世</h3>
            <div className="d-flex mt-4">
              <div className={style.text}>
                进一步提升中华文化影响力，推动中华优秀传统文化创造性转化、创新性发展，增强文化自信，已写入国家十四五规划目标。
                如何让传世名画在不影响保存的情况下成为传承和符号主体、成为美育灯塔，是文化机构及各博物展馆重点考虑的问题；如何运用现代科技彰显、传承和发扬中国传统文化之美，发展并提升大众审美能力和艺术素养，是少样本学习技术研究图像生成的出发点也是该模型的核心优势。
                名画名作大部分已完成高清数字化，在国潮和文创带动下都进行了各种花式动起来、潮起来，通过新媒体艺术的外在形式进行大众传播，接下来，如何更进一步，让更多人去参与、体验，学习、理解该作品及作者风格；如何让用户可以更深度的参与，用创作者的视角，用全局视野去从0到1动手布局，从而理解其创作心境和风格，乃至为我所用，激发自身了解并学习表达方法，潜移默化提升自身及大众的审美能力和文化素养——
              </div>
            </div>
          </div>
        </animated.div>
        <animated.div className="col" style={{x:c.to([-1,0.5,1],[1,0,0]).to(c=>c*100+"%")}}>
          <img
            src={require("@/images/图片 1232.png")}
            style={{ maxWidth: "100%" }}
            alt=""
            onLoad={()=>{let rect=ref.current.getBoundingClientRect();
              setHeight(rect.height-window.innerHeight);}}
          />
          <p className={style.text1}>
            图左为人在白屏上涂抹色块（布局），图右是由机器根据图左布局直接生成的最终图片。
            <br></br>
            <span style={{ background: "yellow" }}>
              中国十大传世名画之一：《千里江山图》（北宋王希孟）
            </span>
          </p>
        </animated.div>
        <div className="mt-5 text-center p-3">© All Rights Reserved. 智媒云图 版权所有 020-87386049 <a style={{ color: "#000" }} href="http://beian.miit.gov.cn">粤ICP备18110983号</a></div>
      </div>
    </animated.div>
  );
};
